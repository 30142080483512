<template>
  <div class="text-center">
    <audio 
      v-if="src != ''"
      :src="src"
      autoplay
      controls />
  </div>
</template>

<script>
  export default {
    name: 'AudioPlayer',
    data () {
      return {
      }
    },
    props: {
      src: { type: String, default: '' }
    },
  }
</script>

<style lang="scss" scoped>
  audio:focus {
    outline: none;
  }
</style>
